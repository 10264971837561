$font-prefix: "~@ibm/plex";

@import "~@ibm/plex/scss/ibm-plex.scss";

$main-color: #017cfe;

html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Lota", sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  // background: rgb(246, 249, 254);
  // background: #f6f9ff;
  // background: #fafcfe;
  background: #f6f9ff;
  overflow-y: hidden;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  font-family: "Lota", sans-serif !important;
}

#layout {
  display: flex;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.main__container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex-grow: 1;
}

.main__content {
  flex-grow: 1;
  padding: 64px 32px;
  width: 100%;
  max-width: 1086px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-self: center;

  scrollbar-width: none; /* Firefox 64 */
  -ms-overflow-style: none; /* Internet Explorer 11 */
  &::-webkit-scrollbar {
    /** WebKit */
    display: none;
  }
}

// input {
//   font-family: inherit;
//   line-height: 40px;
//   border: 1px solid rgb(229, 239, 255);
//   border-radius: 4px;
//   font-size: 14px;
//   padding: 0 14px;
//   color: #4a5669;
// }

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  border-color: #266ff0;
}

button {
  height: 40px !important;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  font-family: inherit;
  background-color: $main-color;
  color: white;
  padding: 10px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.4;

    &:hover {
      background-color: $main-color !important;
    }
  }

  &:hover {
    background-color: rgb(25, 68, 187);
  }

  &.slim {
    height: 32px !important;
    padding: 5px 12px;
  }
}

button.warning {
  height: 40px !important;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  font-family: inherit;
  background-color: #b63128;
  color: white;
  padding: 10px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

button.secondary {
  height: 40px !important;
  border-radius: 4px;
  border: 1px solid#266ff0;
  background-color: #fff;
  color: #266ff0;
  font-size: 14px;
  font-weight: 600;
  font-family: inherit;
  padding: 10px 20px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    // color: rgb(25, 68, 187);
    box-shadow: #e5efff 0px 0px 7px 2px;
  }

  &.slim {
    height: 32px !important;
    padding: 5px 12px;
  }
}

button.return {
  position: relative;
  border: none;
  font-size: 14px;
  font-weight: 600;
  font-family: inherit;
  background-color: transparent;
  color: #266ff0;
  padding: 10px 20px 10px 28px;
  transition: all 0.2s ease-in-out;
  align-self: flex-start;
  cursor: pointer;

  &::before {
    content: url("/assets/icons/left-arrow.svg");
    position: absolute;
    width: 20px;
    left: 0;
  }
}

label {
  color: #687992;
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 8px;
}

span.description,
span.error {
  clear: both;
  color: rgb(104, 121, 146);
  display: block;
  font-size: 12px;
  line-height: 22px;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 4px 0 0;
  overflow: hidden;
}

span.error {
  color: #ff756c;
}

div.toasts {
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  height: auto;
}
