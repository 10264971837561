.pagination {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin-top: 10px;
}
.pagination li {
  display: inline;
  text-align: center;
}
.pagination span {
  float: left;
  display: block;
  font-size: 14px;
  text-decoration: none;
  padding: 5px 12px;
  color: #fff;
  margin-left: -1px;
  border: 1px solid transparent;
  line-height: 1.5;
}
.pagination span.active {
  cursor: default;
}
.pagination span:active {
  outline: none;
}

.modal-1 li:first-child span {
  -moz-border-radius: 6px 0 0 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px 0 0 6px;
}
.modal-1 li:last-child span {
  -moz-border-radius: 0 6px 6px 0;
  -webkit-border-radius: 0;
  border-radius: 0 6px 6px 0;
}
.modal-1 span {
  border-color: #ddd;
  color: #4285f4;
  background: #fff;
}
.modal-1 span:hover {
  background: #eee;
}
.modal-1 span.active,
.modal-1 span:active {
  border-color: #4285f4;
  background: #4285f4;
  color: #fff;
}
