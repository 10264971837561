.toast {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 58px;
  max-width: 320px;
  min-width: 272px;
  background-color: white;
  border-radius: 4px;
  margin-bottom: 12px;
  box-shadow: 0 4px 8px #eff6fd !important;
  align-items: flex-start;
  justify-content: center;
  padding-left: 16px;
  cursor: default;

  * {
    cursor: default;
  }

  div.toast__content {
    display: flex;
    flex-direction: column;
    margin: 12px 44px 12px 0;
  }

  h5 {
    padding: 0;
    margin: 0 0 4px 0;
    font-weight: 600;
    font-size: 15px;
    color: #3d4570;
    display: flex;
    align-items: flex-end;
  }

  span {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    color: #3d4570;
  }

  .toast__tag {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: #2f86eb;
    border-radius: 4px 0 0 4px;

    &.success {
      background: rgb(9, 195, 163);
    }

    &.error {
      background: #ff756c;
    }
  }

  .toast__remove {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 6px);
    right: 16px;
    opacity: 0.7;
  }
}

.toast:last-child {
  margin-bottom: 0;
}
