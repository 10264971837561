@font-face {
  font-family: "Lota";
  font-weight: 400;
  src: url(./Lota/Lota-Regular.woff) format("woff");
}

@font-face {
  font-family: "Lota";
  font-weight: 600;
  src: url(./Lota/Lota-SemiBold.woff) format("woff");
}
