.sidenav {
  grid-area: sidenav;
  position: fixed;
  width: 224px;
  height: 100vh;
  font-family: "overseer-text";
  font-size: 15px;
  font-weight: 300 !important;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  transition: all 0.3s ease-in-out;
  z-index: 2; /* Needs to sit above the hamburger menu icon */
  background-color: #fff;
  border-right: 1px solid rgb(238, 243, 254);
  box-shadow: 0px 3px 8px rgb(229, 239, 255);
}

.sidenav #closeSideNav {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  right: 0;
  padding: 6px !important;
  margin: 21px;
  background: transparent;
  border: none;
}

.sidenav ul {
  list-style: none;
  font-weight: 300;
  text-decoration: none;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-top: 20px;
}

.sidenav ul li a {
  display: flex;
  text-decoration: none;
  background-repeat: no-repeat;
  list-style: none;
  color: rgb(104, 121, 146);
}

.sidenav .nav__item path {
  stroke: rgb(104, 121, 146);
}

.sidenav ul li a:hover {
  color: rgb(0, 137, 255) !important;
}

.sidenav ul li a:hover path {
  stroke: rgb(0, 137, 255) !important;
}

.sidenav ul li a.active {
  color: rgb(0, 137, 255) !important;
  font-weight: 600;
}

.sidenav ul li a.active path {
  stroke: rgb(0, 137, 255);
}

.sidenav ul li a.active .nav__item::before {
  background: rgb(0, 137, 255);
}

.sidenav .nav__item {
  display: flex;
  padding: 9px 27px;
}

.sidenav .nav__item::before {
  content: "";
  height: 24px;
  width: 4px;
  position: absolute;
  left: 0px;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.2s ease 0s;
  background: transparent;
  border-radius: 0px 2px 2px 0px;
}

.sidenav .nav__item #icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
}

.sidenav .nav__item #icon svg {
  width: 16px;
  height: 16px;
  display: block;
  margin: 0 auto;
}

.sidenav .nav__item span {
  font-size: 15px;
  line-height: 22px;
  margin: 1px 0 0 0;
  margin-left: 16px;
}

.separator {
  background-color: rgb(229, 239, 255);
  height: 1px;
  flex-shrink: 0;
  margin: 24px 8px;
  padding: 0px;
  border: none;
}

.st0 {
  fill: transparent;
  stroke-width: 1.2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke: rgb(104, 121, 146);
}

.st0.header {
  stroke-width: 2;
}

/* Selected Effects */

.sidenav.active {
  transform: translateX(0);
}

.sidenav__close-icon {
  position: absolute;
  visibility: visible;
  top: 8px;
  right: 12px;
  cursor: pointer;
  font-size: 20px;
  color: #ddd;
}

.sidenav > #logo {
  height: 64px;
  padding: 16px 20px;
}

.profile {
  width: calc(100% - 40px);
  height: 60px;
  margin: 20px;
  display: inherit;
}

.profile .profileImage {
  width: 60px;
  height: 60px;
  background: #59bac8;
  color: white;
  border-radius: 30px;
  line-height: 60px;
  text-align: center;
  font-size: 24px;
  letter-spacing: 1px;
  margin-right: 10px;
}

.profile .userDetail {
  margin: 5px 0;
}

.profile .userDetail p {
  font-size: 17px;
  margin: 0;
  line-height: 25px;
  color: #a4a7b7;
}

.profile .userDetail p:first-child {
  font-weight: 600;
  color: #333;
}

#logout__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  height: 58px;
  width: 100%;
  left: 0;
  bottom: 0;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-bottom: 18px;
}

#logout__button span {
  display: block;
  text-align: left;
  margin-left: 24px;
  font-family: "overseer-text", Helvetica, sans-serif;
  font-size: 17px;
  color: #a4a7b7;
  line-height: 58px;
}

#logout__button span > svg {
  vertical-align: middle;
  padding-bottom: 0;
  margin-right: 7px;
  margin-left: 3px;
}

#logout__button:hover span {
  color: #2d86ef;
}

#logout__button:hover span path {
  stroke: #2d86ef;
}
