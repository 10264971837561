main {
  padding: 64px 32px 0 32px;
  height: 100%;
}

.watermarks {
  .cancel {
    margin-top: 8px;
    cursor: pointer;
    height: 40px;
    padding: 0 16px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    color: rgb(30, 107, 205);
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    outline: none;
    transition: background 0.2s ease 0s;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(200, 223, 245);
    border-image: initial;
  }
  .cancel:hover {
    color: rgb(38, 134, 240);
    border-color: rgb(150, 195, 237);
  }
  .submit {
    height: 40px;
    padding: 0 16px;
    border: 0;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    outline: 0;
    border-radius: 4px;
    display: inline-block;
    transition: background 0.2s ease;
    -webkit-appearance: button;
    cursor: pointer;
    background-color: rgb(0, 137, 255);
    color: #fff;

    margin-left: 8px;
  }
  .submit:hover {
    background-color: rgb(0, 101, 239);
  }
}

.watermarks > div {
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-gap: 24px;
}

.watermarks > div > div {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.watermarks > div > div > div {
  position: relative;
  background-color: white;
  border-radius: 6px;
  border: none;
  padding: 24px;
  box-shadow: rgba(200, 223, 245, 0.5) 0px 8px 16px;
  overflow: auto;
}

.watermarks .greet {
  h1 {
    font-size: 20px;
    color: rgb(58, 68, 86);
    line-height: 1;
    margin: 20px 0 16px 0;
    font-weight: 600;
  }
  p {
    font-weight: 400;
    margin-bottom: 8px;
    font-size: 16px;
    color: rgb(74, 86, 105);
  }
}

.watermarks .list {
  padding: 0;
  h2 {
    margin: 0;
    color: rgb(58, 68, 86);
    font-weight: 600;
    font-size: 20px;
    padding: 24px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    height: auto;
    // margin-bottom: 24px;
  }
  thead {
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: rgb(229, 239, 255);
    box-shadow: 0 4px 8px -2px rgba(150, 196, 255, 0.25);
    transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
  }
  tr {
    line-height: 40px;
  }
  th {
    margin: 0;
    color: rgba(137, 150, 176, 0.75);

    font-weight: 600;
    font-size: 16px;
    text-align: left;
  }
  td {
    margin: 0;
    color: rgb(74, 86, 105);
    font-weight: 400;
    font-size: 16px;
    text-align: left;
  }
  th:first-child {
    padding-left: 32px;
  }
  td:first-child {
    padding-left: 32px;
    padding-right: 16px;
    width: 1px;
    min-width: 100px;
    white-space: nowrap;
  }
  tbody tr:nth-child(2n + 1) {
    background-color: rgb(250, 251, 255);
  }
}

.watermarks .create__info {
  label {
    color: rgb(74, 86, 105);
    font-weight: 600;
    line-height: 32px;
    padding-bottom: 8px;
  }
  input[type="text"] {
    width: 100%;
    border-radius: 2px;
    border: 1px solid #eaf0fa;
    background-image: none;
    padding: 0 2px 0 10px;
    color: #4c5669;
    filter: none;
    line-height: 40px;
    font-family: inherit;
    font-size: 16px;
    margin-bottom: 16px;
  }
  ::placeholder {
    color: #bbb;
  }
}

.watermarks .create__denominations {
  padding: 0;
  > div {
    width: 100%;
  }
  > div h2 {
    display: inline-block;
    margin: 0;
    color: rgb(58, 68, 86);
    font-weight: 600;
    font-size: 20px;
    padding: 24px 8px 24px 24px;
  }
  > div .subHeader {
    padding: 4px 8px;
    background-color: rgb(46, 206, 168);
    color: white;
    font-weight: 600;
    border-radius: 4px;
    letter-spacing: 0.5px;
  }
  > div .total {
    float: right;
    padding: 4px 8px;
    background-color: rgb(236, 242, 252);
    color: rgb(42, 42, 230);
    font-size: 17px;
    border-radius: 4px;
    margin: 22px 24px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    height: auto;

    thead {
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: rgb(229, 239, 255);
      box-shadow: 0 4px 8px -2px rgba(150, 196, 255, 0.25);
      transform: scale(1, 1);
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
    }
    tr {
      line-height: 40px;
    }
    th {
      margin: 0;
      color: rgba(137, 150, 176, 0.75);

      font-weight: 600;
      font-size: 16px;
      text-align: center;
    }
    td {
      margin: 0;
      color: rgb(74, 86, 105);
      font-weight: 400;
      font-size: 16px;
      text-align: center;
    }
    // th:first-child {
    //   padding-left: 32px;
    // }
    // td:first-child {
    //   padding-left: 32px;
    //   width: 1px;
    //   //   min-width: 100px;
    //   white-space: nowrap;
    // }
    tbody tr:nth-child(2n + 1) {
      background-color: rgb(250, 251, 255);
    }
    th,
    td {
      width: 20%;
    }
    input[type="text"] {
      width: 40px;
      text-decoration: none;
      text-align: center;
      border: 1px solid #e5efff;
      border-radius: 4px;
      line-height: 24px;
      color: #3a4456;
    }
    input[type="text"]:disabled {
      background-color: white;
      color: #ccc;
      border: none;
    }
  }
}

@media only screen and (max-width: 425px) {
  .watermarks {
    padding: 24px;
  }
}

@media only screen and (max-width: 1023px) {
  .watermarks > div {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 1024px) {
  .watermarks .create__denominations {
    max-width: 480px;
  }
}
