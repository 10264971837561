.excel_report {
    color: white;
    background-color: #017cfe;
    font-size: 15px;
    text-decoration: none;
    width: 120px;
    height: 40px;
    align-self: flex-end;
    text-align: center;
    border-radius: 5px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  